
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    // border: 1px solid red;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: flex-start;
    justify-content: center;
    min-height: calc(100vh - 50px);
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: calc(100% + 80px);
        top: -82px;
        position: absolute;
        background-color: #237bd2;
        z-index: -1;
        background-image: url("/assets/images/mainMobileImg.jpg");
        @include lg {
            background-image: url("/assets/images/mainDesctopImg.jpg");
        }
        background-repeat: no-repeat;
        background-size: cover;
    }
    .form {
        // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
        // background-color: #fff;
        // border-radius: 10px;
        z-index: 5;
        width: 100%;
        padding: 30px 15px;
        max-width: 810px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        // border: 1px solid red;
        border-radius: 36px;
        background: rgba(255, 255, 255, 0.85);
        margin: 0 0 70px 0;

        @include lg {
            padding: 50px 100px 30px;
            // margin: 5rem 0;
            margin: 50px 0 111px 0;
            // @media screen and (max-width: 1200px) {
            //     max-width: 500px;
            // }
        }

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px !important;
                    line-height: 1.3;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            // margin-top: 1.5rem;
            // border: 1px solid black;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > div {
                width: 100%;
            }
            > div:nth-child(2),
            > div:nth-child(3) {
                @include lg {
                    width: 48%;
                }
            }
        }
        .form-button {
            // max-width: unset !important;
            // margin: 0 auto !important;
            // margin-top: 5px !important;
            border-radius: 16px !important;
            background: linear-gradient(
                175deg,
                #3790ce 4.15%,
                #266ea1 95.98%
            ) !important;
            transition: all 0.2s ease-in-out;
            font-weight: 700;
            @include lg {
                font-size: 20px;
            }
            // @include lg {
            //     max-width: 450px !important;
            // }
        }
        // .step-block:nth-child(1) {
        //     h1 {
        //         &.step-title {
        //             font-size: 22px !important;
        //             font-weight: bold !important;
        //             margin: 0;
        //             @include lg {
        //                 font-size: 30px !important;
        //                 line-height: 1.3;
        //             }
        //         }
        //     }
        //     .form-field > label {
        //         @apply text-base mb-4 font-bold;
        //         // text-align: center;
        //         @include lg {
        //             @apply text-xl;
        //         }
        //     }
        // }
        .form-field {
            // border: 1px solid green;
            margin-bottom: 10px;
            // width: 100% !important;
            // margin-top: 2rem;
            // max-width: unset;
            // margin: 0 auto;
            // margin-bottom: 0.5rem;
            // @include lg {
            //     max-width: 450px;
            // }
            > label {
                margin-left: 10px;
            }
        }
        // .radio-field {
        //     display: flex;
        //     flex-direction: row;
        //     flex-wrap: wrap;
        //     justify-content: center;

        //     margin-top: 10px;
        //     & > div {
        //         width: 100%;
        //         margin-bottom: 15px;
        //         display: flex;

        //         input {
        //             &:checked + label {
        //                 background-color: $bestbabyformulalawyers-purple-dark;
        //                 color: #fff;
        //                 border: 2px solid $bestbabyformulalawyers-purple-dark;
        //             }
        //         }
        //         label {
        //             border-radius: 3px;
        //             border: 2px solid $bestbabyformulalawyers-purple-dark;
        //             background-color: $bestbabyformulalawyers-purple-light;
        //             color: #000;
        //             font-size: 14px;
        //             display: flex;
        //             align-items: center;
        //             flex-direction: row;
        //             font-weight: 700;
        //             @media screen and (max-width: 767px) {
        //                 padding: 10px 5px;
        //             }
        //             &:hover {
        //                 background-color: $bestbabyformulalawyers-purple-dark;
        //                 color: #fff;
        //             }
        //             span {
        //                 font-size: 16px !important;
        //                 @media screen and (max-width: 767px) {
        //                     font-size: 15px !important;
        //                 }
        //             }
        //             & > div {
        //                 margin-right: 15px;

        //                 & + span {
        //                     margin-top: 0;
        //                     margin-right: inherit;
        //                     width: auto;
        //                 }
        //             }
        //             & > span {
        //                 margin-right: auto;
        //                 width: 100%;
        //             }

        //             @include md {
        //                 min-height: 58px;
        //             }
        //             svg {
        //                 margin-bottom: 10px;
        //                 width: 30px;
        //                 height: auto;
        //             }
        //         }
        //         @include lg {
        //             margin: 0 10px;
        //             margin-bottom: 15px;
        //             width: 100%;
        //         }
        //     }
        //     @include lg {
        //         margin: 0 -10px;
        //     }
        // }
        .input-field,
        .select-field {
            // width: 50%;
            border: 1.5px solid #86bce0;
            border-radius: 16px;
        }
        .select-field {
            background-color: #fff;
        }
        .tcpa {
            // margin-bottom: 10px;
            // border: 1px solid red;
            text-align: center;
            color: #24386b !important;
        }
    }
}
// .progress-background {
//     & > div:last-child {
//         background-color: white;
//     }
// }

// .progress-background {
//     & > div:first-child span {
//         color: #fff;
//     }
//     & > div:last-child {
//         background-color: #f3f3f3;
//     }
// }
