
            @import 'src/styles/mixins.scss';
        
.mainSection {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-color: #d2eeff;
        @include lg {
            background-image: url("/assets/images/getStartedTodayImg.jpg");
        }
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        z-index: -1;
    }
}
