
            @import 'src/styles/mixins.scss';
        
.bgImg {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background: linear-gradient(180deg, #eef8ff 0%, #fff 100%);
        z-index: -1;
    }
}
