
            @import 'src/styles/mixins.scss';
        
.mainSection {
    // background-color: #90b1c8;
    // border: 1px solid red;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #eef8ff;

    @include lg {
        background-image: url("/assets/images/advancedTechnologyImg.jpg");
    }
}
